// latestNewsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const apiUrl = process.env.REACT_APP_UPLOAD_URL;
// Create an async thunk for fetching the latest news
export const fetchLatestNews = createAsyncThunk(
  "latestNews/fetchLatestNews",
  async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/news?populate=*&sort=publishedAt:DESC&pagination[limit]=30`
      );
   
      return response.data.data; // Assuming the response contains the "data" field
    } catch (error) {
      throw error;
    }
  }
);

const latestNewsSlice = createSlice({
  name: "latestNews",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLatestNews.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLatestNews.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchLatestNews.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default latestNewsSlice.reducer;
