import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchNewsByName } from "../../redux/slices/newsSlice";
import { Link } from "react-router-dom";
import talkswithkalpana from "../../assets/talks-with-kalpana.jpg";

const DataDisplay = ({ categoriesWithHeadings }) => {
  const dispatch = useDispatch();
  const { data, isLoading, isError } = useSelector((state) => state.news);

  useEffect(() => {
    // Fetch news data for the first category only
    const firstCategory = categoriesWithHeadings[0].name;
    dispatch(fetchNewsByName({ nameParam: firstCategory }));
  }, [dispatch, categoriesWithHeadings]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error fetching news data.</div>;
  }

  const hardcodedData = {
    title: "Explore compelling Fireside Chats with Kalpana Singhal and esteemed tech leaders as they share insights on trends, digital transformation challenges, strategies, priorities and success stories in the realm of tech leadership.",
    image: talkswithkalpana,
    categoryLink: "/Talks-with-Kalpana",
    categoryLinkSeeMore: "/Talks-with-Kalpana",
    categories: ["Talks-with-Kalpana"],
  };

  const dynamicData = categoriesWithHeadings.map(({ name, heading }) => {
    const categoryData = data[name];

    if (!categoryData || categoryData.length === 0) return null;

    const firstItem = categoryData[0];

    const categoryLink =
      firstItem.category && firstItem.category.categoryName
        ? `/${firstItem.category.categoryName.replace(/\s+/g, "-")}/${firstItem.attributes.title}`
        : `/${name.replace(/\s+/g, "-")}/${firstItem.attributes.slug}`;

    const categoryLinkSeeMore =
      firstItem.category && firstItem.category.categoryName
        ? `/${firstItem.category.categoryName.replace(/\s+/g, "-")}`
        : `/${name.replace(/\s+/g, "-")}`;

    return (
      <div key={name} className="lg:flex flex-col gap-2 w-full">
        <div className="text-[28px] font-semibold uppercase">{heading}</div>
        <div className="lg:flex gap-5 py-6 w-full">
          <Link to={categoryLink} className="lg:w-[100%]">
            <img
              src={
                firstItem.attributes.image.data.attributes.formats?.medium?.url
                  ? process.env.REACT_APP_UPLOAD_URL + firstItem.attributes.image.data.attributes.formats.medium.url
                  : process.env.REACT_APP_UPLOAD_URL + firstItem.attributes.image.data.attributes.url
              }
              alt={firstItem.attributes.title}
              loading="lazy"
              className="object-cover w-full lg:w-full lg:h-44 h-48 rounded-md"
            />
          </Link>
          <div className="lg:flex flex-col gap-1 w-full text-ellipsis overflow-hidden">
            <p className="text-lg lg:pt-0 pt-2 font-semibold text-[16px] text-[#4601FA] hover:text-black transition-all duration-200">
              {firstItem.attributes.title}
            </p>
          </div>
        </div>
        <div className="flex items-end justify-end">
          <Link to={categoryLinkSeeMore} className="border border-blue-800 lg:px-6 px-3 py-1 text-blue-800 font-bold">
            See More
          </Link>
        </div>
      </div>
    );
  });

  return (
    <div className="lg:flex gap-5 lg:px-5 md:px-5 px-0 w-full">
      {dynamicData}
      <div className="lg:flex flex-col gap-2 w-full">
        <div className="text-[28px] font-semibold uppercase">Talks with Kalpana</div>
        <div className="h-1 w-20 bg-[#4601FA]"></div>
        <div className="lg:flex gap-5 py-6 w-full">
          <Link to={hardcodedData.categoryLink} className="lg:w-[100%]">
            <img
              src={hardcodedData.image}
              alt="Talks with Kalpana"
              loading="lazy"
              className="object-cover w-full lg:w-full lg:h-44 h-48 rounded-md"
            />
          </Link>
          <div className="lg:flex flex-col gap-1 w-full text-ellipsis overflow-hidden">
            <p className="text-lg lg:pt-0 pt-2 font-semibold text-[16px] text-[#4601FA] hover:text-black transition-all duration-200">
              {hardcodedData.title}
            </p>
          </div>
        </div>
        <div className="flex items-end justify-end">
          <Link to={hardcodedData.categoryLinkSeeMore} className="border border-blue-800 lg:px-6 px-3 py-1 text-blue-800 font-bold">
            See More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DataDisplay;
