import React, { useEffect, Suspense, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomAds } from "../redux/slices/customAdsSlice";
import logo from "../assets/cxotv-header-logo.jpg";
import "../App.css";
import Section from "../components/common/Section";
import HomeSlider from "../components/common/HomeSlider";
const Header = lazy(() => import("../components/common/Header"));
const Navbar = lazy(() => import("../components/common/Navbar"));
const NewsSection = lazy(() => import("../components/common/NewsSection"));
const TwoCategoryData = lazy(() =>
  import("../components/common/TwoCategoryData")
);
const Ad = lazy(() => import("../components/common/Ad"));
const Footer = lazy(() => import("../components/common/Footer"));

const Home = () => {
  const dispatch = useDispatch();
  const { isAdLoading, isError } = useSelector((state) => state.customAds);

  useEffect(() => {
    dispatch(fetchCustomAds());
    document.title =
      "Latest Technology News – CXO TV Today & Tech Updates and Videos";
  }, [dispatch]);

  if (isAdLoading) {
    return (
      <div className="grid min-h-[calc(100vh-3.5rem)] place-items-center">
        <div className="spinner"></div>
      </div>
    );
  }

  if (isError) {
    return (
      <div>
        <Suspense
          fallback={
            <div className="grid min-h-[calc(100vh-3.5rem)] place-items-center">
              <div className="spinner"></div>
            </div>
          }
        >
          <Header logo={logo} />
        </Suspense>
        <div className="flex flex-col items-center justify-center py-20 gap-8">
          <p className="lg:text-[40px] text-red-600 font-fira capitalize">
            Server Error
          </p>
          <div className="text-red-600 font-roboto text-4xl font-bold">
            Oops! Something Went Wrong
          </div>
          <p className="text-gray-700 text-lg text-center">
            Sorry, there seems to be an error on the server side. Please refresh
            the page or try again later.
          </p>
        </div>

        <Suspense
          fallback={
            <div className="grid min-h-[calc(100vh-3.5rem)] place-items-center">
              <div className="spinner"></div>
            </div>
          }
        >
          <Footer />
        </Suspense>
      </div>
    );
  }

  const categoryData = [
    { name: "Tech Thursday", title: "TECH THURSDAY" },
    { name: "BFSI", title: "CXO BFSI" },
    { name: "Health Technology", title: "CXO HEALTH" },
    { name: "Education Technology", title: "CXO EDUCATION" },
    { name: "Marketing Mondays", title: "MARKETING" },
    { name: "Technology", title: "TECH NEWS" },
  ];

  const categoriesWithHeadings1 = [
    { name: "Technology", heading: "Technology" },
    { name: "Interviews", heading: "Interviews" },
  ];

  const categoriesWithHeadings2 = [
    { name: "what's popular", heading: "WHAT’S POPULAR" },
    { name: "editor's choice", heading: "EDITOR'S CHOICE" },
  ];

  const names = [
    "Tech Priorities",
    "Tech Thursday",
    "Interviews",
    "CFO Playbook",
    "Developers",
    "Cyberwatch",
    "CXO Talk",
    "Marketing Mondays",
    "Talks with Kalpana",
    "CEO Talk",
  ];

  const sliderLeft = ["CXO Talk", "Marketing Mondays", "CEO Talk"];

  return (
    <>
      <Suspense
        fallback={
          <div className="grid min-h-[calc(100vh-3.5rem)] place-items-center">
            <div className="spinner"></div>
          </div>
        }
      >
        <div className="flex flex-col gap-3 mx-auto w-full">
          <Header logo={logo} />
          <Navbar />

          <div className="mx-auto lg:w-[50%] w-[90%]">
            <Ad name="tech-summit-2023" />
          </div>

          <div className="py-2">
            <HomeSlider category="Trending News" names={sliderLeft} />
          </div>
          <div>
            <Section names={names} />
          </div>

          <div className="w-full py-10 px-4 mx-auto items-center bg-[#F3F2F6]">
            <TwoCategoryData
              active={true}
              categoriesWithHeadings={categoriesWithHeadings1}
            />
          </div>

          <NewsSection categoryData={categoryData} />

          <div className="mx-auto lg:w-[50%] w-[90%]">
            <Ad name="Data-cloud" />
          </div>

          <div className="w-full py-10 px-5 mx-auto items-center bg-[#F3F2F6]">
            <TwoCategoryData categoriesWithHeadings={categoriesWithHeadings2} />
          </div>

          <Footer />

          <div className="mx-auto lg:w-[50%] w-full">
            <Ad name="Data-cloud" />
          </div>
        </div>
      </Suspense>
    </>
  );
};

export default Home;
