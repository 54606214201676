import React, { useState, useEffect, Suspense, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomAds } from "../redux/slices/customAdsSlice";
import axios from "axios";
import { RxCross1 } from "react-icons/rx";
import { MdKeyboardArrowRight } from "react-icons/md";
import logo from "../assets/cxotv-header-logo.jpg";
import "../App.css"; // Assuming a spinner CSS file
import LatestNews from "../redux/slices/latestNews";
import Section from "../components/common/Section";
import HomeSlider from "../components/common/HomeSlider";
import DataDisplay from "../components/common/DataDisplay";

const Header = lazy(() => import("../components/common/Header"));
const Navbar = lazy(() => import("../components/common/Navbar"));
const Slider = lazy(() => import("../components/common/Slider"));
const NewsSection = lazy(() => import("../components/common/NewsSection"));
const TwoCategoryData = lazy(() =>
  import("../components/common/TwoCategoryData")
);
const Ad = lazy(() => import("../components/common/Ad"));
const Footer = lazy(() => import("../components/common/Footer"));

const Home = () => {
  const dispatch = useDispatch();
  const { isAdLoading, isError } = useSelector((state) => state.customAds);

  const [showPopup, setShowPopup] = useState(false);
  const [formData, setFormData] = useState({
    FullName: "",
    CompanyName: "",
    Phone: "",
    Email: "",
  });

  const [subscribed, setSubscribed] = useState(false);

  useEffect(() => {
    dispatch(fetchCustomAds());
    document.title =
      "Latest Technology News – CXO TV Today & Tech Updates and Videos";

    const userSubscribed = localStorage.getItem("subscribed");
    if (userSubscribed) {
      setSubscribed(true);
    } else {
      const timer = setTimeout(() => {
        setShowPopup(true);
      }, 20000);
      return () => clearTimeout(timer);
    }
  }, [dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_UPLOAD_URL}/api/news-latters`, {
        data: formData,
      });
      setShowPopup(false);
      setSubscribed(true);
      localStorage.setItem("subscribed", true);
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  if (isAdLoading) {
    return (
      <div className="grid min-h-[calc(100vh-3.5rem)] place-items-center">
        <div className="spinner"></div>
      </div>
    );
  }

  if (isError) {
    return (
      <div>
        <Suspense
          fallback={
            <div className="grid min-h-[calc(100vh-3.5rem)] place-items-center">
              <div className="spinner"></div>
            </div>
          }
        >
          <Header logo={logo} />
        </Suspense>
        <div className="flex flex-col items-center justify-center py-20 gap-8">
          <p className="lg:text-[40px] text-red-600 font-fira capitalize">
            Server Error
          </p>
          <div className="text-red-600 font-roboto text-4xl font-bold">
            Oops! Something Went Wrong
          </div>
          <p className="text-gray-700 text-lg text-center">
            Sorry, there seems to be an error on the server side. Please refresh
            the page or try again later.
          </p>
        </div>

        <Suspense
          fallback={
            <div className="grid min-h-[calc(100vh-3.5rem)] place-items-center">
              <div className="spinner"></div>
            </div>
          }
        >
          <Footer />
        </Suspense>
      </div>
    );
  }

  const categoryData = [
    { name: "Tech Thursday", title: "TECH THURSDAY" },
    { name: "BFSI", title: "CXO BFSI" },
    { name: "Health Technology", title: "CXO HEALTH" },
    { name: "Education Technology", title: "CXO EDUCATION" },
    { name: "Marketing Mondays", title: "MARKETING" },
    { name: "Technology", title: "TECH NEWS" },
  ];

  const categoriesWithHeadings1 = [
    { name: "Technology", heading: "Technology" },
  
  ];

  const categoriesWithHeadings2 = [
    { name: "what's popular", heading: "WHAT’S POPULAR" },
    { name: "editor's choice", heading: "EDITOR'S CHOICE" },
  ];

  const names = [
    "Tech Priorities",
    "Tech Thursday",
    "Interviews",
    "CFO Playbook",
    "Developers",
    "Cyberwatch",
    "CXO Talk",
    "Marketing Mondays",
    "Talks with Kalpana",
    "CEO Talk",
    
  ];
  // ,

  const sliderLeft = [
    "CXO Talk",
    "Marketing Mondays",
    "Talks with Kalpana",
    "CEO Talk",
  ];

  return (
    <>
      <Suspense
        fallback={
          <div className="grid min-h-[calc(100vh-3.5rem)] place-items-center">
            <div className="spinner"></div>
          </div>
        }
      >
        <div className="flex flex-col gap-3 mx-auto w-full">
          <Header logo={logo} />
          <Navbar />

          <div className="mx-auto lg:w-[50%] w-[90%]">
            <Ad name="tech-summit-2023" />
          </div>

          <div className="py-2">
            <HomeSlider category="Trending News" names={sliderLeft} />
          </div>
          <div>
            <Section names={names} />
          </div>

          <div className="w-full py-10 px-4 mx-auto items-center bg-[#F3F2F6]">
            <DataDisplay
              active={true}
              categoriesWithHeadings={categoriesWithHeadings1}
            />
          </div>

          <NewsSection categoryData={categoryData} />

          <div className="mx-auto lg:w-[50%] w-[90%]">
            <Ad name="Data-cloud" />
          </div>

          <div className="w-full py-10 px-5 mx-auto items-center bg-[#F3F2F6]">
            <TwoCategoryData categoriesWithHeadings={categoriesWithHeadings2} />
          </div>

          <Footer />

          <div className="mx-auto lg:w-[50%] w-full">
            <Ad name="Data-cloud" />
          </div>

          <div className="flex flex-col gap-4 mx-auto w-full items-center z-30">
            {!subscribed && showPopup && (
              <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
                <div className="bg-white p-8 rounded-lg max-w-md relative">
                  <button
                    className="absolute top-0 right-0 p-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowPopup(false);
                    }}
                  >
                    <RxCross1 className="w-6 h-6" />
                  </button>

                  <h2 className="text-xl mb-4">Subscribe to our Newsletter!</h2>
                  <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="flex flex-col sm:flex-row sm:space-x-2 items-center">
                      <input
                        type="text"
                        id="fullName"
                        name="FullName"
                        value={formData.FullName}
                        onChange={handleChange}
                        placeholder="Full Name*"
                        className="border border-gray-300 rounded px-3 py-2 w-full"
                        required
                      />
                      <input
                        type="text"
                        id="companyName"
                        name="CompanyName"
                        value={formData.CompanyName}
                        onChange={handleChange}
                        placeholder="Company Name*"
                        className="border border-gray-300 rounded px-3 py-2 w-full"
                      />
                    </div>
                    <div className="flex flex-col sm:flex-row sm:space-x-2 items-center">
                      <input
                        type="tel"
                        id="phone"
                        name="Phone"
                        value={formData.Phone}
                        onChange={handleChange}
                        placeholder="Phone*"
                        className="border border-gray-300 rounded px-3 py-2 w-full"
                        minLength={10}
                        maxLength={10}
                        required
                      />
                      <input
                        type="email"
                        id="email"
                        name="Email"
                        value={formData.Email}
                        onChange={handleChange}
                        placeholder="Email*"
                        className="border border-gray-300 rounded px-3 py-2 w-full"
                        required
                      />
                    </div>
                    <button
                      type="submit"
                      className="flex items-center justify-around bg-[#4F46E5] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full mx-auto"
                    >
                      Submit
                      <MdKeyboardArrowRight className="w-6 h-6" />
                    </button>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </Suspense>
    </>
  );
};

export default Home;
