import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const apiUrl = process.env.REACT_APP_UPLOAD_URL;

// Define the initial state to store news data as an array
const initialState = {
  data: {
    APAC: [],
    EMEA: [],
    INDIA: [],
    USA: [],
    "Trending News": [],
  },
  isLoading: false,
  isError: false,
};

// Define the async thunk to fetch news data by category name
export const fetchRegionNews = createAsyncThunk(
  "regionNews/fetchRegionNews",
  async (region, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/news?populate=*&sort=publishedAt:DESC&filters[$or][0][categories].data[name][$eq]=${region}&filters[$or][1][subcategories][name][$eq]=${region}&pagination[limit]=20`
      );
      console.log(`Fetched ${region} news:`, response.data.data); // Log fetched data
      return { region, data: response.data.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Create the region news slice
const regionNewsSlice = createSlice({
  name: "regionNews",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRegionNews.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchRegionNews.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.data[action.payload.region] = action.payload.data;
      })
      .addCase(fetchRegionNews.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default regionNewsSlice.reducer;

