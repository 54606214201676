import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const apiUrl = process.env.REACT_APP_UPLOAD_URL;
const initialState = {
  data: [],
  isLoading: false,
  isError: false,
  currentPage: 1,
  totalPages: 1,
  itemsPerPage: 25, // Assuming the default items per page
};

export const fetchNewscategoryAndsubcategory = createAsyncThunk(
  "categoryAndsubcategory",
  async ({ nameParam, page }, { getState }) => {
   
    
    try {
      const response = await axios.get(
        `${apiUrl}/api/news?populate=*&sort=publishedAt:DESC&filters[$or][0][categories].data[name][$eq]=${nameParam}&filters[$or][1][subcategories].data[name][$eq]=${nameParam}&pagination[page]=${page}
        `
      );

      const { data, meta } = response.data;

     

      const state = getState().categoryAndsubcategoryNews;

      return {
        data,
        totalPages: meta.pagination.pageCount,
      };
    } catch (error) {
      throw error;
    }
  }
);


const categoryAndsubcategoryNewsSlice = createSlice({
  name: "categoryAndsubcategoryNews",
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNewscategoryAndsubcategory.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchNewscategoryAndsubcategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.data = action.payload.data;
        state.totalPages = action.payload.totalPages;
      })
      
      .addCase(fetchNewscategoryAndsubcategory.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const { setCurrentPage } = categoryAndsubcategoryNewsSlice.actions;

export default categoryAndsubcategoryNewsSlice.reducer;

